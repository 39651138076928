import React from 'react';
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear any authentication tokens or user data here
        localStorage.removeItem('accessToken'); // Example for removing token from localStorage

        // Redirect to the root URL
        navigate('/');
    };

    return (
        <div className="w-64 bg-gray-900 p-4 space-y-16">
            <div className="text-lg font-bold"></div>
            <ul className="space-y-6">
                <li><a href="/dashboard" className="block px-4 py-2 my-4 rounded text-xl hover:bg-gray-700">Home</a></li>
                <li><a href="/bookings" className="block px-4 py-2 my-4 rounded text-xl hover:bg-gray-700">Bookings</a></li>
                <li><a href="/customers" className="block px-4 py-2 my-4 rounded text-xl hover:bg-gray-700">Customers</a></li>
                <li><a href="/reviews" className="block px-4 py-2 my-4 rounded text-xl hover:bg-gray-700">Reviews</a></li>
                <li><a href="/slots" className="block px-4 py-2 my-4 rounded text-xl hover:bg-gray-700">Slots</a></li>
                <li><a href="/promocode" className="block px-4 py-2 my-4 rounded text-xl hover:bg-gray-700">Promo Code</a></li>
                <li>
                    <button className="block px-4 py-2 my-4 rounded text-xl hover:bg-gray-700" onClick={handleLogout}>
                        Logout
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;
