import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import moment from 'moment-timezone';
import axios from 'axios';
import config from '../config';

const SlotsTable = ({ onSave, onClose }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [existingSlots, setExistingSlots] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchExistingSlots(selectedDate);
  }, [selectedDate]);

  const fetchExistingSlots = async (date) => {
    setLoading(true);
    try {
      const formattedDate = format(date, 'yyyy-MM-dd');
      const accessToken = localStorage.getItem('accessToken');
      const response = await axios.get(`${config.apiBaseUrl}/api/slots`, {
        params: { date: formattedDate },
        headers: {
            'Authorization': `Bearer ${accessToken}`
          }
      });
      
      // Since the response is already in IST, just extract the hour and minute
      const existingTimes = response.data.map(slot => {
        const timeStr = slot.time.split(' ')[1]; // Extract time part "13:00:00"
        return timeStr.substring(0, 5); // Keep only "13:00"
      });

      console.log('Existing times:', existingTimes);
      setExistingSlots(existingTimes);
    } catch (error) {
      console.error('Error fetching existing slots:', error);
    }
    setLoading(false);
  };


  const generateTimeOptions = () => {
    const times = [];
    // Create date in IST starting at 9 AM
    const startTime = moment.tz(selectedDate, 'Asia/Kolkata').startOf('day').hour(9);
    const endTime = moment.tz(selectedDate, 'Asia/Kolkata').startOf('day').hour(21);

    while (startTime.isBefore(endTime)) {
      times.push(startTime.clone());
      startTime.add(60, 'minutes');
    }
    return times;
  };

  const isTimeSlotDisabled = (time) => {
    const timeStr = time.format('HH:mm'); // Format as "13:00"
    return existingSlots.includes(timeStr);
  };

  const toggleTimeSlot = (time) => {
    if (isTimeSlotDisabled(time)) return;
    
    const timeStr = time.format();
    if (timeSlots.some(slot => slot.format() === timeStr)) {
      setTimeSlots(timeSlots.filter(slot => slot.format() !== timeStr));
    } else {
      setTimeSlots([...timeSlots, time]);
    }
  };

  const handleSave = () => {
    if (timeSlots.length === 0) {
      alert('Please select at least one time slot');
      return;
    }

    const formattedDate = format(selectedDate, 'yyyy-MM-dd');
    const formattedTimes = timeSlots.map(time => {
      // Create a properly formatted time string
      const timeStr = time.format('HH:mm:ss');
      console.log('Formatted time for saving:', timeStr);
      return timeStr;
    });

    console.log('Saving slots:', {
      date: formattedDate,
      times: formattedTimes
    });

    onSave(formattedDate, formattedTimes);
  };
  
  return (
    <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl">
      <div className="px-6 py-4 border-b border-gray-200 bg-gray-50 rounded-t-lg flex justify-between items-center">
        <h2 className="text-xl font-semibold text-gray-800">Create New Slots</h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <span className="text-2xl">&times;</span>
        </button>
      </div>

      <div className="p-6 space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Select Date</label>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => {
              setSelectedDate(date);
              setTimeSlots([]);
            }}
            dateFormat="yyyy-MM-dd"
            minDate={new Date()}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg text-gray-700 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Select Time Slots
            <span className="text-gray-500 ml-2 text-xs">(Time in IST)</span>
          </label>
          
          {loading ? (
            <div className="text-center py-8 text-gray-500">Loading available slots...</div>
          ) : (
            <div className="grid grid-cols-4 gap-2 md:grid-cols-6 lg:grid-cols-8">
              {generateTimeOptions().map((time, index) => {
                const isDisabled = isTimeSlotDisabled(time);
                const isSelected = timeSlots.some(slot => slot.format() === time.format());
                
                return (
                  <button
                    key={index}
                    onClick={() => toggleTimeSlot(time)}
                    disabled={isDisabled}
                    className={`
                      p-2 rounded-lg text-sm font-medium transition-all
                      ${isDisabled 
                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed relative overflow-hidden'
                        : isSelected
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                      }
                    `}
                  >
                    {time.format('h:mm A')}
                    {isDisabled && (
                      <div className="absolute inset-0 bg-gray-200 bg-opacity-75 flex items-center justify-center text-xs">
                        Booked
                      </div>
                    )}
                  </button>
                );
              })}
            </div>
          )}
        </div>

        {timeSlots.length > 0 && (
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="text-sm font-medium text-gray-700 mb-2">
              Selected Time Slots ({timeSlots.length})
            </h3>
            <div className="flex flex-wrap gap-2">
              {timeSlots
                .sort((a, b) => a.valueOf() - b.valueOf())
                .map((slot, index) => (
                  <div
                    key={index}
                    className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-blue-100 text-blue-800"
                  >
                    {slot.format('h:mm A')}
                    <button
                      onClick={() => toggleTimeSlot(slot)}
                      className="ml-2 text-blue-600 hover:text-blue-800"
                    >
                      ×
                    </button>
                  </div>
                ))}
            </div>
          </div>
        )}

        <div className="flex justify-end pt-4 border-t border-gray-200 space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            disabled={timeSlots.length === 0}
            className={`
              px-4 py-2 rounded-lg text-white
              ${timeSlots.length === 0
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-blue-500 hover:bg-blue-600'
              }
            `}
          >
            Create {timeSlots.length} Slot{timeSlots.length !== 1 ? 's' : ''}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SlotsTable;