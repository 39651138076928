import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Sidebar from './Sidebar';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import config from '../config';
import { format } from 'date-fns';
import SlotsTable from './SlotsTable';

const Slots = () => {
  const [slots, setSlots] = useState([]);
  const [date, setDate] = useState(new Date());
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    loadSlots(date);
  }, [date]);

  const loadSlots = async (date) => {
    try {
      const formattedDate = format(date, 'yyyy-MM-dd');
      const accessToken = localStorage.getItem('accessToken');
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const response = await axios.get(`${config.apiBaseUrl}/api/slots`, {
        params: { date: formattedDate },
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'X-Timezone': timezone
        }
      });

      const convertedSlots = response.data.map(slot => ({
        ...slot,
        date: new Date(slot.date),
        time: format(new Date(slot.time), 'hh:mm a')
      }));

      setSlots(convertedSlots);
    } catch (error) {
      console.error('Error loading slots:', error);
    }
  };

  const handleToggleStatus = async (slotId, status) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await axios.put(`${config.apiBaseUrl}/api/slots/${slotId}`, { status }, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      loadSlots(date);
    } catch (error) {
      console.error('Error updating slot status', error);
    }
  };

  const handleAddSlot = () => {
    setIsModalOpen(true);
  };

  const handleDeleteSlot = async (slotId) => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await axios.delete(`${config.apiBaseUrl}/api/slots/${slotId}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      loadSlots(date);
    } catch (error) {
      console.error('Error deleting slot', error);
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-800 text-white">
      <Sidebar />
      <div className="flex-1 p-6">
        <h1 className="text-2xl font-bold mb-4">Available Slots</h1>
        <div className="flex items-center mb-4 text-black">
          <DatePicker
            selected={date}
            onChange={(date) => setDate(date)}
            dateFormat="yyyy-MM-dd"
            className="rounded p-2 border border-gray-300"
          />
          <button onClick={() => loadSlots(date)} className="bg-blue-500 text-white px-4 py-2 rounded ml-2">Search</button>
          <button onClick={() => setDate(new Date(date.setDate(date.getDate() - 1)))} className="bg-gray-500 text-white px-4 py-2 rounded ml-2">Previous Day</button>
          <button onClick={() => setDate(new Date(date.setDate(date.getDate() + 1)))} className="bg-gray-500 text-white px-4 py-2 rounded ml-2">Next Day</button>
          <button onClick={handleAddSlot} className="bg-green-500 text-white px-4 py-2 rounded ml-auto">Add New Slot</button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5">
          {slots.map(slot => (
            <div key={slot.sid} className={`p-4 rounded-md flex flex-col items-center justify-center ${slot.status ? 'bg-green-600' : 'bg-red-500'}`}>
              <h2 className="text-2xl font-bold mb-2">
                {format(new Date(slot.date), 'dd MMM yyyy')}
              </h2>
              <p className="text-lg mb-2">Time: {slot.time}</p>
              {slot.status ? (
                <p className="text-lg mb-2">Status: Slot Available</p>
              ) : (
                <p className="text-lg mb-2">Status: Slot Closed</p>
              )}
              <p 
                className="text-lg mb-2" 
                dangerouslySetInnerHTML={{ 
                  __html: slot.appointment ? 
                    `Customer Name: ${slot.appointment.name}<br>Call Name: ${slot.appointment.app_name}<br>Meeting: ${slot.appointment.link}` : 
                    '' 
                }} 
              />
              <button
                onClick={() => handleToggleStatus(slot.sid, !slot.status)}
                className={`px-4 py-2 rounded mt-2 ${slot.status ? 'bg-red-500' : 'bg-green-500'} text-white`}
              >
                {slot.status ? 'Close' : 'Open'}
              </button>
              <button 
                onClick={() => handleDeleteSlot(slot.sid)} 
                className="bg-gray-600 text-white px-4 py-2 rounded mt-2"
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <SlotsTable
            onClose={() => setIsModalOpen(false)}
            onSave={async (date, times) => {
              try {
                await axios.post(
                  `${config.apiBaseUrl}/api/slots/add`,
                  {
                    date,
                    times
                  },
                  {
                    headers: {
                      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                    }
                  }
                );
                
                setIsModalOpen(false);
                loadSlots(date);
              } catch (error) {
                console.error('Error adding slots:', error);
                alert('Error creating slots. Please try again.');
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Slots;