import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from '../components/Login';
import Bookings from '../components/Bookings';
import Customers from '../components/Customers';
import BookingDetails from '../components/BookingDetails';
import Dashboard from '../components/Dashboard';
import Slots from '../components/Slots';
import ProtectedRoute from './ProtectedRoute';
import Reviews from '../components/Reviews';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={
        <ProtectedRoute requireAuth={false}>
          <Login />
        </ProtectedRoute>
      } />
      <Route path="/dashboard" element={
        <ProtectedRoute requireAuth={true}>
          <Dashboard />
        </ProtectedRoute>
      } />
      <Route path="/bookings" element={
        <ProtectedRoute requireAuth={true}>
          <Bookings />
        </ProtectedRoute>
      } />
      <Route path="/bookings/:bookid" element={
        <ProtectedRoute requireAuth={true}>
          <BookingDetails />
        </ProtectedRoute>
      } />
      <Route path="/bookings/:bookid" element={
        <ProtectedRoute requireAuth={true}>
          <BookingDetails />
        </ProtectedRoute>
      } />
<Route path="/customers" element={
        <ProtectedRoute requireAuth={true}>
          <Customers />
        </ProtectedRoute>
      } />
      <Route path="/slots" element={
        <ProtectedRoute requireAuth={true}>
          <Slots />
        </ProtectedRoute>
      } />
      <Route path="/reviews" element={
        <ProtectedRoute requireAuth={true}>
          <Reviews />
        </ProtectedRoute>
      } />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
