import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import config from '../config';

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [filterOption, setFilterOption] = useState('');
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const reviewsPerPage = 10;

  // Fetch reviews from API
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const token = localStorage.getItem('accessToken'); // Retrieve token from local storage or state
        const response = await axios.get(`${config.apiBaseUrl}/api/reviews/get`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setReviews(response.data.data); // Update this line to match the API response structure
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchReviews();
  }, []);

  // Pagination Logic
  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);

  const handleFilterOptionChange = (e) => {
    setFilterOption(e.target.value);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearchSubmit = () => {
    alert(`Searching for ${filterOption} with text: ${searchText}`);
    // Add search logic if necessary
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  return (
    <div className="flex min-h-screen bg-gray-800 text-white">
      <Sidebar />
      <div className="p-6 m-5 min-h-screen flex-1">
        <h1 className="text-3xl font-bold mb-6">Review List</h1>

        {/* Search Section */}
        <div className="flex flex-col mb-6">
          <div className="flex items-center mb-4">
            <label className="mr-4 text-lg">Search by:</label>
            <select
              value={filterOption}
              onChange={handleFilterOptionChange}
              className="mr-4 p-2 rounded text-black"
            >
              <option value="">Select Option</option>
              <option value="name">Name</option>
              <option value="email">Email</option>
            </select>
            <input
              type="text"
              value={searchText}
              onChange={handleSearch}
              className="mr-4 p-2 rounded text-black"
              placeholder="Enter search text"
            />
            <button
              onClick={handleSearchSubmit}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Search
            </button>
          </div>
        </div>

        {/* Reviews Table */}
        <table className="min-w-full bg-white text-black">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Customer</th>
              <th className="py-2 px-4 border-b">OrderID</th>
              <th className="py-2 px-4 border-b">Result</th>
              <th className="py-2 px-4 border-b">Star</th>
              <th className="py-2 px-4 border-b">Feedback</th>
              <th className="py-2 px-4 border-b">Post</th>
              <th className="py-2 px-4 border-b">Date</th>
              <th className="py-2 px-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentReviews.map((review, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b text-center">{review.book_name}</td>
                <td className="py-2 px-4 border-b text-center">{review.bid}</td>
                <td className="py-2 px-4 border-b text-center">{review.v_result}</td>
                <td className="py-2 px-4 border-b text-center">{review.star}</td>
                <td className="py-2 px-4 border-b text-center">{review.feedback}</td>
                <td className="py-2 px-4 border-b text-center">{review.post ? 'Yes' : 'No'}</td>
                <td className="py-2 px-4 border-b text-center">{new Date(review.date).toLocaleDateString()}</td>
                <td className="py-2 px-4 border-b text-center">
                  <a href={`/bookings/${review.bkid}`}>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      View
                    </button>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination Controls */}
        <div className="flex justify-between items-center mt-6">
          <div>
            Showing {indexOfFirstReview + 1} to{' '}
            {Math.min(indexOfLastReview, reviews.length)} of {reviews.length} reviews
          </div>
          <div className="flex">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
            >
              Previous
            </button>
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={indexOfLastReview >= reviews.length}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
